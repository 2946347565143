export default class CartApp {
    constructor(base_app) {
        this.base_app = base_app;
        this.gallery_url = $('#mgaGalleryUrl').attr('data-url');
        this.$card_body = $('#mgaCartCardBody');
        this.$order_total = $('#mgaOrderTotal');
        this.$cart_count = $('#mgaCartCount');
        this.$cart_total = $('#mgaCartTotal');
        this.$promo_collapse_btn = $('#mgaCartPromoCollapse');
        this.$promo_wrapper = $('#mgaCartPromoWrapper');
        this.$promo_form_body = $('#mgaCartPromoFormBody');
        this.$discount_tbl = $('#mgaCartDiscounts');
    }

    start() {
        var self = this;

        $('.mga-remove-btn').on('click', function (event) {
            event.preventDefault();
            var id = $(this).data('item');
            self.remove_item(id);
        });

        $('.mga-cart-item-count-btn').on('click', function () {
            var action = $(this).data('action');
            var id = $(this).data('item');
            self.update_item_count(action, id);
        });

        this.$promo_collapse_btn.on('click', function () {
            let $icon = $(this).find('i');
            $icon.removeClass('bi-caret-up').removeClass('bi-caret-down');
            console.log($(this).attr('aria-expanded'));
            if ($(this).attr('aria-expanded') == 'false') {
                $icon.addClass('bi-caret-down');
            } else {
                $icon.addClass('bi-caret-up');
            }
        });
    }

    update_item_count(action, id) {
        this.base_app.updateCart({
            id: id,
            action: action,
            notify_on_sucess: false,
            notify_fail: 'Не удалось изменить количество',
            clb_success: (data) => {
                let item_count = data['item_count'];
                if (data['cart_count'] < 1) {
                    this.empty_cart_html();
                } else {
                    if (item_count == 0) {
                        this.remove_item_row(id);
                    } else {
                        this.set_item_row_data(
                            id,
                            item_count,
                            data['item_total'],
                        );
                        this.update_order_counters({
                            cart_count: data['cart_count'],
                            cart_total: data['cart_total'],
                            order_total: data['order_total'],
                        });
                        this.update_order_discounts({
                            personal: data['p_discount'],
                            client: data['cl_discount'],
                        });
                    }
                    this.clear_promo();
                }
            },
        });
    }

    remove_item(id) {
        this.base_app.updateCart({
            id: id,
            action: 'remove',
            notify_on_sucess: false,
            notify_fail: 'Не удалось убрать композицию',
            clb_success: (data) => {
                if (data['cart_count'] < 1) {
                    this.empty_cart_html();
                } else {
                    this.remove_item_row(data['item']);
                    this.update_order_counters({
                        cart_count: data['cart_count'],
                        cart_total: data['cart_total'],
                        order_total: data['order_total'],
                    });
                    this.update_order_discounts({
                        personal: data['p_discount'],
                        client: data['cl_discount'],
                    });
                    this.clear_promo();
                }
            },
        });
    }

    set_item_row_data(id, count, total) {
        let $item_row = $(`.mga-item-row[data-item=${id}]`);
        $item_row.find('.mga-cart-item-count').val(count);
        let total_str = priceToString(total);
        $item_row.find('.mga-order-list-price').html(total_str);
    }

    remove_item_row(id) {
        var $item_row = $(`.mga-item-row[data-item=${id}]`);
        $item_row.remove();
    }

    update_order_counters({ cart_count = 0, cart_total = 0, order_total = 0 }) {
        this.$cart_count.html(cart_count);
        this.$cart_total.html(priceToString(cart_total));
        this.$order_total.html(priceToString(order_total));
    }

    update_order_discounts({ personal = 0, client = 0 }) {
        if (personal == 0 && client == 0) {
            this.$discount_tbl.html('');
            this.$discount_tbl.hide();
        } else {
            let html = `<thead><tr><td colspan="2">Скидки и акции:</td></tr></thead><tbody class="fs-6 fw-light">`;
            if (personal > 0) {
                let discount = priceToString(personal);
                html += `<tr><td>Персональная скидка</td><td>-${discount}</td></tr>`;
            }
            if (client > 0) {
                let discount = priceToString(client);
                html += `<tr><td>Скидка клиента</td><td>-${discount}</td></tr>`;
            }
            html += '</tbody>';
            this.$discount_tbl.html(html);
            this.$discount_tbl.show();
        }
    }

    clear_promo() {
        this.$promo_collapse_btn
            .find('#mgaCartPromoHeader')
            .text('Применить промокод');
        let html = `<div class="col col-md-6 col-lg-4">
<div class="input-group input-group-sm">
<input name="promo_code" type="text" class="form-control" placeholder="Введите промо-код" 
aria-label="Recipient's username" aria-describedby="mgaPromoSubmitBtn">
<input type="hidden" name="promo_action" value="set" id="id_promo_action">
<input type="submit" class="btn btn-outline-secondary" id="mgaPromoSubmitBtn" value="Применить">
</div>`;
        this.$promo_form_body.html(html);
    }

    empty_cart_html() {
        let html = `<div class="d-flex flex-column w-100 text-center justify-content-center">
<h5 class="pb-3">В корзине нет ни одной композиции</h5>
<div class="d-flex flex-row flex-wrap w-100 text-center justify-content-center">
<a href="${this.gallery_url}" class="btn btn-outline-secondary me-lg-2 mb-2 mga-outline-btn">
Добавить композиции</a>
</div></div>`;
        this.$card_body.html(html);
    }
}

function priceToString(price) {
    let parts = price.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    let string = parts.join(',') + '&#8381;';
    return string;
}
