export default class ToastNotification {
    constructor() {
        let toastElement = document.getElementById("mgaNotification");
        this.toast = new bootstrap.Toast(toastElement,
            {
                animation: true,
                autohide: true,
                delay: 2000
            });
        this.$title = $("#mgaNotificationHeader");
        this.$body = $("#mgaNotificationText");
    }

    notify({ title = "", text = "" }) {
        if (this.toast.isShown()) {
            this.toast.hide();
        }
        this.$title.html(title);
        this.$body.html(text);
        this.toast.show();
    }

    error({ title = "", text = "" }) {
        this.notify({
            title: `<span class="mga-text-error">&#11198; ${title}</span>`,
            text: text
        });
    }

    success({ title = "", text = "" }) {
        this.notify({
            title: `<span class="mga-text-success">&#10003; ${title}</span>`,
            text: text
        });
    }
}