import LandingApp from "./js/landing";
import BaseApp from "./js/base";
import GalleryApp from "./js/gallery";
import start_composition from "./js/composition";
import OrderTraceApp from "./js/order_trace";
import CartApp from "./js/cart";
import UserCabinetApp from "./js/user_cabinet";
import CheckoutApp from "./js/checkout";
import PolicyApp from "./js/policy";

$(function () {
    var $page_id = $("#page_id");

    var base_app = new BaseApp();
    base_app.start();

    if ($page_id.length > 0) {
        const pid = $page_id.attr("value");

        switch (pid.toLowerCase()) {
            case "landing":
                new LandingApp().start();
                break;
            case "gallery":
                new GalleryApp().start();
                break;
            case "composition":
                start_composition();
                break;
            case "order_trace":
                new OrderTraceApp().start();
                break;
            case "cart":
                new CartApp(base_app).start();
                break;
            case "checkout":
                new CheckoutApp().start();
                break;
            case "user_cabinet":
                new UserCabinetApp(base_app).start();
                break;
            case "policy":
                new PolicyApp().start();
                break;
            default:
                break;
        }
    }
});