import ToastNotification from "./notification";

export default class BaseApp {
    constructor() {
        this.likeIconChecked = "bi-heart-fill";
        this.likeIconUnchecked = "bi-heart";
        this.cart_api = $("#mgaCartApiUrl").data("url");
        this.comp_like_api = $("#mgaCompositionLikeUrl").data("url");
        this.csrftoken = $("input[name=csrfmiddlewaretoken]").val();
        this.$cart_badge = $("#mgaNavCartCount");
        this.cart_count = $("#mgaInitCartCount").data("count");

        this.notification = new ToastNotification();
        this.like_notify_title = "Избранное";
        this.updateCartCount();
    }

    start() {
        var self = this;

        // Like btn click action
        $("body").on("click", "button.mga-product-like", function (event) {
            var item = $(this).data("item");
            self.updateLike(item);
        });

        // buy button click action
        $("body").on("click", ".mga-buy-btn", function (event) {
            var id = $(this).data("item");
            var action = $(this).data("action")
            self.updateCart(
                {
                    id: id,
                    action: action,
                    notify_title: "Корзина",
                    notify_success: "Композиция добавленна",
                    notify_fail: "Не далось добавить"
                }
            );
        });
    }

    likeClick($likeBtn) {
        let $status = $likeBtn.data("status");
        let $icon = $likeBtn.find("i");
        let item = $likeBtn.data("item");
        if ($status == "checked") {
            $likeBtn.removeClass("checked");
            $icon.removeClass(this.likeIconChecked);
            $icon.addClass(this.likeIconUnchecked);
            $likeBtn.data("status", "unchecked");
        } else if ($status == "unchecked") {
            $likeBtn.addClass("checked");
            $icon.removeClass(this.likeIconUnchecked);
            $icon.addClass(this.likeIconChecked);
            $likeBtn.data("status", "checked");
        };

        this.updateLike(item);
    }

    setLikeBtn($likeBtn, active = false) {
        let $icon = $likeBtn.find("i");
        $likeBtn.removeClass("checked");
        $icon.removeClass(this.likeIconChecked).removeClass(this.likeIconUnchecked);

        if (active) {
            $likeBtn.addClass("checked");
            $icon.addClass(this.likeIconChecked);
        } else {
            $icon.addClass(this.likeIconUnchecked);
        }
    }

    setItemLikeBtn(id, active = false) {
        var self = this;
        $(`button.mga-product-like[data-item="${id}"]`).each(function () {
            let $icon = $(this).find("i");
            $(this).removeClass("checked");
            $icon.removeClass(self.likeIconChecked).removeClass(self.likeIconUnchecked);

            if (active) {
                $(this).addClass("checked");
                $icon.addClass(self.likeIconChecked);
            } else {
                $icon.addClass(self.likeIconUnchecked);
            }
        });
    }

    updateCart(
        {
            id,
            action,
            notify_on_success = true,
            notify_on_fail = true,
            notify_title = "Корзина",
            notify_success = "Операция успешна",
            notify_fail = "Операция не удалась",
            clb_success = undefined,
            clb_fail = undefined
        }
    ) {
        if (!this.value_is_empty(this.cart_api) && !this.value_is_empty(this.csrftoken)) {
            var url = `${this.cart_api}${action}/${id}/`;

            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': this.csrftoken
                }
            }).then((response) => {
                var data = { success: false, errors: ["Failed to parse response"] };
                if (response.status == 200) {
                    try {
                        return response.json();
                    } catch (error) {
                        return data;
                    }
                }
                return data;
            }).then((data) => {
                if (data["success"]) {
                    this.cart_count = data["cart_count"];
                    this.updateCartCount();
                    if (this.is_function(clb_success)) {
                        clb_success(data);
                    }
                    if (notify_on_success) {
                        this.notification.success({ title: notify_title, text: notify_success });
                    }
                } else {
                    if (this.is_function(clb_fail)) {
                        clb_fail(data);
                    }
                    if (notify_on_fail) {
                        this.notification.error({ title: notify_title, text: notify_fail });
                    }
                }
            });
        } else {
            if (notify_on_fail) {
                var data = { success: false, errors: ["Не удалось отправить запрос"] };
                if (this.is_function(clb_fail)) {
                    clb_fail(data);
                }
                this.notification.error({ title: notify_title, text: notify_fail });
            }
        }
    }

    updateCartCount() {
        this.$cart_badge.html(this.cart_count);
        if (this.cart_count > 0) {
            this.$cart_badge.show();
        } else {
            this.$cart_badge.hide();
        }
    }

    value_is_empty(value) {
        if (value === undefined || value === null || value == "") {
            return true;
        }
        return false;
    }

    is_function(value) {
        if (value instanceof Function && value !== undefined && value != null) {
            return true;
        }

        return false;
    }

    updateLike(id) {
        var notify_error_text = "Не удалось изменить статус";
        if (!this.value_is_empty(this.comp_like_api) && !this.value_is_empty(this.csrftoken)) {
            var api_url = `${this.comp_like_api}${id}/`;
            fetch(api_url,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': this.csrftoken
                    }
                }).then((response) => {
                    var data = { success: false, errors: ["Failed to make request"] }
                    if (response.status == 200) {
                        try {
                            return response.json();
                        } catch {
                            return data;
                        }
                    }
                    return data;
                }).then((data) => {
                    if (data["success"]) {
                        this.setItemLikeBtn(data["item"], data["like"]);
                    } else {
                        var errors = data["errors"]
                        var error_text = notify_error_text;
                        if (errors !== undefined && errors.length > 0) {
                            error_text = errors.join("\n");
                        }
                        this.notification.error({ title: this.like_notify_title, text: error_text });
                    }
                });
        } else {
            this.notification.error({ title: this.like_notify_title, text: notify_error_text });
        }
    }

    fetch_api(
        {
            url,
            mehtod = "POST",
            in_data = {},
            notify_on_success = true,
            notify_on_fail = true,
            notify_title = "Корзина",
            notify_success = "Операция успешна",
            notify_fail = "Операция не удалась",
            clb_success = undefined,
            clb_fail = undefined,
        }
    ) {
        if (this.value_is_empty(url) || this.value_is_empty(this.csrftoken)) {
            if (notify_on_fail) {
                this.notification.error({ title: notify_title, text: notify_fail });
            }
            return;
        }

        try {
            var in_data_json = JSON.stringify(in_data);
        } catch {
            if (notify_on_fail) {
                this.notification.error({ title: notify_title, text: notify_fail });
            }
            return;
        }

        fetch(url, {
            method: mehtod,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': this.csrftoken
            },
            body: JSON.stringify(in_data_json)
        }).then((response) => {
            var data = { success: false, errors: [notify_fail] };
            if (response.status == 200) {
                try {
                    return response.json();
                } catch {
                    return data;
                }
            }
            return data;
        }).then((data) => {
            if (data["success"]) {
                if (notify_on_success) {
                    this.notification.success({ title: notify_title, text: notify_success });
                }
                if (this.is_function(clb_success)) {
                    clb_success(data);
                }
            } else {
                if (notify_on_fail) {
                    this.notification.error({ title: notify_title, text: notify_fail });
                }
                if (this.is_function(clb_fail)) {
                    clb_fail(data);
                }
            }
        });
    }
}