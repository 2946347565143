export default class PolicyApp {
    constructor() {
        this.doc_name = $("#mgaDocName").val();
        this.$offertaTab = $("#oferta-tab");
        this.$agreementTab = $("#agreement-tab");
        this.$policytTab = $("#policy-tab");

        console.log(this.doc_name);
    }

    start() {
        switch (this.doc_name) {
            case "oferta":
                this.$offertaTab.click();
                break;
            case "agreement":
                this.$agreementTab.click();
                break;
            case "policy":
                this.$policytTab.click();
                break;
        }
    }
}