export default class LandingApp {
    constructor() {}

    start() {
        var self = this;
        // MainGal, New and Promo activation
        new bootstrap.Carousel('#mainGallery');
        new bootstrap.Carousel('#newProductCarousel');
        new bootstrap.Carousel('#promoProductCarousel');

        var $navbarLogo = $('#navbarLogo > img');
        $navbarLogo.hide();

        // Show navbar logo if page scrolled
        if (window.scrollY > 20) {
            $navbarLogo.fadeIn();
        }

        $(window).scroll(function () {
            if ($(this).scrollTop() > 20) {
                $navbarLogo.fadeIn();
            } else {
                $navbarLogo.fadeOut();
            }
        });

        // Scroll to top on nav bar log pressed
        $('.navbarLogo').on('click', function (event) {
            event.preventDefault();
            window.scrollTo(0);
        });

        // Rotate carousel if carousel nav button pressed
        $('.mga-landing-promo-navbtn').on('click', function () {
            let carousel_id = $(this).data('target');
            let direction = $(this).data('direction');
            let carousel = new bootstrap.Carousel(carousel_id);
            if (direction == 'next') {
                carousel.next();
            } else if (direction == 'prev') {
                carousel.prev();
            }
        });

        // hide offcanvas if page position link pressed
        $('.mga-page-nav-btn').on('click', function (event) {
            event.preventDefault();
            $('#offcanvasNavCloseBtn').click();
            let $target = $($(this).attr('href'));

            let topPosition = $target.offset().top;
            window.scrollTo(0, topPosition - 40);
        });

        // Order search button press and enter key at input fld
        $('#mgaTraceOrderBtn').on('click', function () {
            self.go_to_order();
        });

        $('#mgaOrderNumInput').keypress(function (event) {
            var keycode = event.keyCode ? event.keyCode : event.which;
            if (keycode == '13') {
                let order = $(this).val();
                self.go_to_order(order);
            }
        });
    }

    go_to_order(order) {
        var order = $('#mgaOrderNumInput').val();
        var order_url = $('#mgaTraceOrderBtn').data('url');

        if (
            order_url !== undefined &&
            order_url !== null &&
            order !== undefined &&
            order !== null
        ) {
            order = '' + order;
            order = order.replace(/[\W_]+/g, '');
            window.location.pathname = order_url + order;
        }
    }
}
