export default class GalleryApp {
    constructor() {
        this.item_color_border = 'border: 1px solid #444';
        this.items = [];
        this.items_len = 0;
        this.chunk_size = 10;
        this.chunk_max = 0;
        this.next_chunk = 0;

        var items_scr = document.getElementById('mgaDataItems');
        if (items_scr != null) {
            this.items = JSON.parse(items_scr.textContent);
            this.items_len = this.items.length;
            this.chunk_max = Math.ceil(this.items_len / this.chunk_size);
        }

        this.$listWrapper = $('#mgaGalleryListWrapper');
        this.$moreBtnBlock = $('#mgaShowMoreBlock');
        this.$moreBtn = $('#mgaShowMoreBtn');
        this.moreBtnPosition = -1;
        this.$spinner = $('#mgaCompositionLoading');
    }

    start() {
        var self = this;
        this.list_is_empty();

        $('#navLinkGallery').addClass('active');

        var offcanvasFilterObj = document.getElementById('offcanvasFilters');
        const offcanvasFilter = new bootstrap.Offcanvas(offcanvasFilterObj);

        this.load_next_chunk();

        // More button click handler
        this.$moreBtn.on('click', function () {
            self.load_next_chunk();
        });

        // Load more items if scrolled to more button
        $(window).on('scroll', function () {
            if (self.more_btn_in_viewport()) {
                self.load_next_chunk();
            }
        });

        // Sorting change
        $('#mgaSortSelect').on('change', function () {
            let val = Number($(this).val());
            self.clear_list();
            self.next_chunk = 0;
            self.$spinner.removeClass('d-none').addClass('d-flex');
            self.item_list_sort(val);
            self.$spinner.removeClass('d-flex').addClass('d-none');
            self.load_next_chunk();
        });

        // Open filter button handler
        $('#filterToggleBtn').on('click', function (event) {
            offcanvasFilter.toggle();
        });

        // Filter clear button handler
        $('#mgaGalleryFilterForm .mga-bnt-clear').on('click', function () {
            var $form = $('#mgaGalleryFilterForm');
            $form.find(':input').each(function () {
                var $this = $(this);
                switch ($this.attr('type')) {
                    case 'number':
                        $this.val('');
                        break;
                    case 'checkbox':
                        $this.prop('checked', false);
                        break;
                    case 'radio':
                        if ($this.val() == 'all' || $this.val() == '-1') {
                            $this.prop('checked', true);
                        }
                        break;
                }
            });
            $form.find('#filterBtnSubmit1').click();
        });
    }

    clear_list() {
        this.$moreBtnBlock.removeClass('d-flex').addClass('d-none');
        this.$listWrapper.empty();
    }

    list_is_empty() {
        if (this.items_len == 0) {
            $('#mgaCompositionNotFound')
                .removeClass('d-none')
                .addClass('d-flex');
        } else {
            this.$moreBtnBlock.removeClass('d-none').addClass('d-flex');
        }
    }

    build_card(item, id = undefined) {
        if (id != undefined && Number.isInteger(id)) {
            item = this.items.find((comp) => comp.id == id);
        }

        if (
            item === undefined ||
            item === null ||
            Array.isArray(item) ||
            typeof item !== 'object'
        ) {
            return { carousel_id: '', html: '' };
        }

        var id = item['id'];
        var img_len = 0;
        var item_url = item['url'];
        var item_gallery = item['gallery'];
        var item_promo = item['promo'];
        var item_color = item['colors'];
        var discount = item['discount'];
        var price = item['price'];
        var old_price = item['old_price'];
        var favorite = item['favorite'];
        var sell_id = item['sell'];
        var sell = '';

        var carousel_id = `mgaItemCarousel${id}`;

        if (favorite === undefined) {
            favorite = false;
        }

        if (id === undefined) {
            id = -1;
        }

        if (item_gallery !== undefined) {
            img_len = item_gallery.length;
        }

        if (sell_id == 0) {
            sell = 'в наличии';
        } else {
            sell = 'под заказ';
        }

        // Carousel indicators and images
        var carousel_ind = '';
        var carousel_inner = '';

        if (img_len > 0) {
            carousel_ind +=
                `<button type="button" data-bs-target="#${carousel_id}" data-bs-slide-to="0" class="active" ` +
                `aria-current="true" aria-label="Slide 1"></button>`;

            carousel_inner =
                `<div class="carousel-item h-100 active justify-content-center align-items-center">` +
                `<div class="d-flex h-100 p-2 pb-5 justify-content-center">` +
                `<a href="${item_url}" class="mga-gallery-image-link">` +
                `<img src="${item_gallery[0]['url']}" class="rounded mga-gallery-product-image">` +
                `</a>` +
                `</div></div>`;

            if (img_len > 1) {
                for (let i = 1; i < img_len; i++) {
                    carousel_ind +=
                        `<button type="button" data-bs-target="#${carousel_id}" data-bs-slide-to="${i}" ` +
                        `aria-current="true" aria-label="Slide 1"></button>`;

                    carousel_inner +=
                        `<div class="carousel-item h-100 justify-content-center align-items-center">` +
                        `<div class="d-flex h-100 p-2 pb-5 justify-content-center">` +
                        `<a href="${item_url}" class="mga-gallery-image-link">` +
                        `<img src="${item_gallery[i]['url']}" class="rounded mga-gallery-product-image">` +
                        `</a>` +
                        `</div></div>`;
                }
            }
        }

        // [START] Item card
        var card_html =
            `<div class="card mga-product mga-product-card-gallery">` +
            `<div class="row mt-1 g-0 h-100">`;

        // * Item card carousel: START
        card_html +=
            `<div class="col-md-4 mga-product-card-carousel">` +
            `<div id="${carousel_id}" class="carousel slide w-100 h-100" data-bs-theme="dark">` +
            // carousel indicators
            `<div class="carousel-indicators">${carousel_ind}</div>` +
            // carousel inner
            `<div class="carousel-inner h-100">${carousel_inner}</div>`;
        // carousel buttons
        if (img_len > 1) {
            // button: prev
            card_html +=
                `<button class="carousel-control-prev" type="button" data-bs-target="#${carousel_id}" data-bs-slide="prev">` +
                `<span class="carousel-control-prev-icon" aria-hidden="true"></span>` +
                `<span class="visually-hidden">Previous</span></button>` +
                // button: next
                `<button class="carousel-control-next" type="button" data-bs-target="#${carousel_id}" data-bs-slide="next">` +
                `<span class="carousel-control-next-icon" aria-hidden="true"></span>` +
                `<span class="visually-hidden">Next</span></button>`;
        }
        card_html += `</div></div>`;
        // * [END] Item card carousel

        // * [START] Item card information
        card_html +=
            `<div class="col-md-8"><div class="card-body pe-md-2 h-100">` +
            `<div class="d-flex flex-column justify-content-between h-100">`;

        // ** [START] Item description
        card_html += `<div class="d-flex flex-column w-100">`;

        // *** [START] header
        card_html +=
            `<div class="d-flex justify-content-between align-items-start">` +
            // title
            `<a href="${item_url}" ` +
            `class="card-title pe-2 mga-gallery-item-link link-underline-dark link-offset-2 link-underline-opacity-50">` +
            `<h5>${item['name']}</h5>` +
            `</a>`;

        // like
        if (favorite) {
            card_html +=
                `<button class="me-md-2 mga-product-like checked" data-item="${id}">` +
                `<i class="bi bi-heart-fill"></i>` +
                `</button></div>`;
        } else {
            card_html +=
                `<button class="me-md-2 mga-product-like" data-item="${id}">` +
                `<i class="bi bi-heart"></i>` +
                `</button></div>`;
        }
        // *** [END] header

        // *** [START] promotions: START
        if (item_promo !== undefined) {
            card_html += `<div class="d-flex flex-row py-1">`;
            card_html +=
                `<span class="badge me-1" ` +
                `style="background-color: ${item_promo['bg_color']}; color: ${item_promo['text_color']}">` +
                `${item_promo['name']}</span>`;
            card_html += `</div>`;
        }
        // *** [END] promotions

        // *** description text
        card_html += `<p class="card-text pb-md-2 pe-md-4">${item['description']}</p>`;

        // *** [START] Item stats
        card_html += `<div class="d-flex flex-column w-100 mga-product-card-stats">`;

        // **** [START] colors
        if (item_color !== undefined && item_color != null) {
            card_html += `<div class="d-flex flex-row pb-2">`;

            for (let i in item_color) {
                card_html += `<div class="me-1 mga-product-card-color-box" style="background-color: ${item_color[i]['color']};`;
                if (item_color[i]['border']) {
                    card_html += `${this.item_color_border};`;
                }
                card_html += `"></div>`;
            }

            card_html += `</div>`;
        }
        // **** [END] colors

        // **** [START] water
        if (item['water'] !== undefined) {
            let water_text = 'Нет';
            if (item['water']) {
                water_text = 'Есть';
            }
            card_html += `<span>Имитация воды: ${water_text}</span>`;
        }
        // **** [END] water
        // **** [START] material
        // **** [END] material
        if (item['material']) {
            let material = item['material'];
            card_html += `<span>Материал(ы): ${material}</span>`;
        }
        // **** [START] kashpo
        if (item['kashpo']) {
            let kashpo = item['kashpo'];
            card_html += `<span>Кашпо: ${kashpo}</span>`;
        }
        // **** [END] kashpo
        // **** [START] size
        if (item['size_spec']) {
            let width = item['width'];
            let height = item['height'];
            let depth = item['depth'];

            let label = '';
            let size_text = '';
            if (height !== undefined && height !== null) {
                label += 'В';
                size_text += height;
            }
            if (width !== undefined && width !== null) {
                label += 'xШ';
                size_text += ` x ${width}`;
            }
            if (depth !== undefined && depth !== null) {
                label += 'xГ';
                size_text += ` x ${depth}`;
            }
            label = label.replace(/^x/, '');
            size_text = size_text.replace(/^\sx\s/, '');

            card_html += `<span>Размер (${label}), см:&nbsp;${size_text}</span>`;
        }
        // **** [END] size
        // **** [START] weight
        if (item['weight']) {
            let weight = item['weight'];
            card_html += `<span>Вес, кг: ${weight}</span>`;
        }
        card_html += `</div>`;
        // *** [END] Item stats

        card_html += `</div>`;
        // ** [END] Item description

        // *** [START] Item price
        card_html +=
            `<hr class="d-md-none mb-0 pb-0">` +
            '<div class="d-flex flex-row justify-content-between align-items-center pb-1 mt-auto mga-product-price-row">' +
            `<div class="d-flex flex-column">`;

        if (discount == true && old_price !== undefined) {
            card_html += `<span class="mga-text-price-old">${PRICE_FORMAT.format(
                old_price,
            )}</span>`;
        }
        card_html +=
            `<span class="mga-text-price">${PRICE_FORMAT.format(
                price,
            )}</span>` + `</div>`;
        card_html +=
            `<div class="d-flex flex-column p-0 pe-md-2 m-0">` +
            `<button class="mga-buy-btn" data-item="${id}" data-action="add"><i class="bi bi-cart-plus"></i></button>` +
            `<span class="mga-product-card-sell-text">${sell}</span>` +
            `</div>` +
            `</div>`;
        // *** [END] Item price

        card_html += `</div></div></div>`;
        // ** [END] Item card information

        card_html += `</div></div>`;
        // [END] Item card

        return {
            carousel_id: carousel_id,
            html: card_html,
        };
    }

    load_next_chunk() {
        if (this.next_chunk > this.chunk_max) {
            return;
        }

        var start = this.next_chunk * this.chunk_size;
        var end = start + this.chunk_size;
        for (let i = start; i < end && i < this.items_len; i++) {
            let carousel = this.build_card(this.items[i]);
            if (carousel.carousel_id !== '') {
                this.$listWrapper.append(carousel.html);
                new bootstrap.Carousel(`#${carousel.carousel_id}`);
            }
        }

        this.next_chunk += 1;
        if (this.next_chunk >= this.chunk_max) {
            this.$moreBtnBlock.removeClass('d-flex').addClass('d-none');
        } else if (this.$moreBtnBlock.is(':hidden')) {
            this.$moreBtnBlock.removeClass('d-none').addClass('d-flex');
        }
        this.update_more_btn_posision();
    }

    update_more_btn_posision() {
        if (this.$moreBtnBlock.is(':visible')) {
            this.moreBtnPosition =
                this.$moreBtnBlock.offset().top +
                this.$moreBtnBlock.height() +
                20;
        } else {
            this.moreBtnPosition = -1;
        }
    }

    more_btn_in_viewport() {
        return (
            this.moreBtnPosition > 0 &&
            this.moreBtnPosition <= $(window).scrollTop() + $(window).height()
        );
    }

    item_list_sort(code = 0) {
        if (value_not_set(code) || code == 0 || this.items_len < 2) {
            return;
        }
        switch (code) {
            case 1:
                this.items.sort((a, b) =>
                    this.item_sort_compare(a, b, 'price'),
                );
                break;
            case 2:
                this.items.sort((a, b) =>
                    this.item_sort_compare(a, b, 'price', true),
                );
                break;
            case 3:
                this.items.sort((a, b) =>
                    this.item_sort_compare(a, b, 'height'),
                );
                break;
            case 4:
                this.items.sort((a, b) =>
                    this.item_sort_compare(a, b, 'height', true),
                );
                break;
            case 5:
                this.items.sort((a, b) =>
                    this.item_sort_compare(a, b, 'width'),
                );
                break;
            case 6:
                this.items.sort((a, b) =>
                    this.item_sort_compare(a, b, 'width', true),
                );
                break;
        }
    }

    item_sort_compare(a, b, field, desc = false) {
        let result = 0;
        let afield = a[field];
        let bfield = b[field];
        let a_not_set = value_not_set(afield);
        let b_not_set = value_not_set(bfield);
        if (!a_not_set && !b_not_set) {
            if (afield >= bfield) {
                result = 1;
            } else if (afield < bfield) {
                result = -1;
            }
            if (desc) {
                result *= -1;
            }
            return result;
        }
        if (!value_not_set(afield) && value_not_set(bfield)) {
            return -1;
        }
        if (value_not_set(afield) && !value_not_set(bfield)) {
            return 1;
        }
    }
}

function value_not_set(value) {
    return value === undefined || value === NaN || value == null;
}

let PRICE_FORMAT = Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
});
