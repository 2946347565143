export default class CheckoutApp {
    constructor() {
        this.$orderBtn = $('#mgaCheckoutOrderBtn');
        this.$checkout_form = $('#mgaCheckoutForm');
    }

    start() {
        var self = this;
        this.$orderBtn.on('click', function () {
            console.log('Button clicked');
            self.$checkout_form.trigger('submit');
        });
    }
}
