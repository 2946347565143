import BaseApp from "./base";

export default class UserCabinetApp {
    constructor(base_app = null) {
        this.order_item = {
            icon_opened: "bi-chevron-down",
            icon_closed: "bi-chevron-right"
        }

        this.base_app = null;

        if (base_app instanceof BaseApp) {
            this.base_app = base_app
        } else {
            this.base_app = new BaseApp();
        }

        this.notification = {
            favorite: {
                title: "Избранное",
                default_text: "Не удалось убрать из избранного"
            }
        };
    }

    start() {
        var self = this;

        $("button.mga-user-order-button").on("click", function () {
            var $icon = $(this).find(".mga-user-order-btn-ico > i");

            if ($icon.hasClass(self.order_item.icon_closed)) {
                $icon.removeClass(self.order_item.icon_closed);
                $icon.addClass(self.order_item.icon_opened);
            } else {
                $icon.removeClass(self.order_item.icon_opened);
                $icon.addClass(self.order_item.icon_closed);
            }
        });

        $("button.mga-favorite-remove-btn").on("click", function () {
            var item = $(this).data("item");
            self.removeFromFavorites(item);
        });
    }

    removeFromFavorites(id) {
        if (this.base_app.value_is_empty(id) || this.base_app.value_is_empty(this.base_app.comp_like_api)) {
            this.base_app.notification.error({
                title: this.notification.favorite.title,
                text: this.notification.favorite.default_text
            });
            return;
        }

        var api_url = `${this.base_app.comp_like_api}${id}/`;

        this.base_app.fetch_api({
            url: api_url,
            notify_on_fail: true,
            notify_on_success: false,
            notify_title: "Избранное",
            notify_fail: "Не удалось убрать из избранного",
            clb_success: (data) => {
                if (data["like"] == false) {
                    this.removeFavoriteElement(data["item"]);
                }
            },
        });
    }

    removeFavoriteElement(id) {
        $(`.mga-product-card-favorite[data-item='${id}']`).remove();
    }

}