export default class OrderTraceApp {

    constructor() {
        this.trace_url = $("#mgaTraceOrderBtn").data("url");
        this.has_url = this.trace_url != "" && this.trace_url !== undefined && this.trace_url !== null;
    }

    start() {
        var self = this;

        // Order search btn press
        $("#mgaTraceOrderBtn").on("click", function () {
            let order_num = $("#mgaOrderNumInput").val();
            self.go_to_order(order_num);
        })

        $("#mgaOrderNumInput").keypress(function (event) {
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == "13") {
                let order = $(this).val();
                self.go_to_order(order);
            }
        });
    }

    go_to_order(order_num) {
        if (this.trace_url) {
            if (order_num !== "" && order_num !== undefined && order_num !== null) {
                order_num = "" + order_num;
                order_num = order_num.replace(/[\W_]+/g, "");
                window.location.pathname = this.trace_url + order_num;
            }
        }
    }
}